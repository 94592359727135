<template>
    <el-dialog :visible.sync="show" width="700px" :close-on-click-modal="false" title="司机回框明细">
        <el-table :data="tableData" :size="$store.getters.size" height="500px" border :default-sort="{prop:'type'}"
            v-loading="loading" show-summary :summary-method="summaryMethod">
            <el-table-column label="客户名称" prop="companyName" width="250px"></el-table-column>
            <el-table-column label="出框数量" prop="leaveBasketNum" width="100px"></el-table-column>
            <el-table-column label="回框数量" prop="returnBasketNum" width="100px"></el-table-column>
            <el-table-column label="出豆腐板数量" prop="leavePlankNum" width="100px"></el-table-column>
            <el-table-column label="回豆腐板数量" prop="returnPlankNum" width="100px"></el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
            <el-button :size="$store.getters.size" @click="handleClose">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { getDriverContainerDetail } from '@/api/delivery/delivery'

    export default {
        name: "driverContainerDetail",
        data(){
            return {
                show: false,
                tableData: [],
                loading: false
            }
        },
        methods: {
            handleOpen(param){
                this.tableData = [];
                this.loading = true;
                getDriverContainerDetail(param).then(({code, data})=>{
                    this.loading = false;
                    if(code === 0){
                        this.tableData = data;
                    }
                })
                this.show = true;
            },
            handleClose(){
                this.tableData = [];
                this.show = false;
            },
            summaryMethod({ columns, data }) {
                let sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '总计';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] += '';
                    } else {
                        sums[index] = '';
                    }
                });
                return sums;
            }
        }
    }
</script>

<style scoped>

</style>
