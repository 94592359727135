<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-select filterable placeholder="选择仓库" v-model="queryParams.warehouseId" clearable>
                    <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index"
                               :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryParams.driverId" placeholder="请选择司机" clearable filterable>
                    <el-option v-for="(item, index) in driverOption" :label="item.driverName" :value="item.guid" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                        :editable="false"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        v-model="queryParams.createTime"
                        placeholder="请选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="querySearch">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="tableData" :size="$store.getters.size" v-loading="loading" ref="table" row-key="driverId" border>
            <el-table-column label="司机" prop="driverName" width="120"></el-table-column>
            <el-table-column label="日期" prop="createTime" width="100"></el-table-column>
            <el-table-column label="出框" prop="leaveBasketNum" width="100" align="center"></el-table-column>
            <el-table-column label="应回框" prop="expectBasketNum" width="100" align="center"></el-table-column>
            <el-table-column label="实际回框" prop="returnBasketNum" width="100" align="center"></el-table-column>
            <el-table-column label="框差距" prop="shortBasketNum" width="100" align="center"></el-table-column>
            <el-table-column label="出豆腐板" prop="leavePlankNum" width="100" align="center"></el-table-column>
            <el-table-column label="应回豆腐板" prop="expectPlankNum" width="100" align="center"></el-table-column>
            <el-table-column label="实际回豆腐板" prop="returnPlankNum" width="100" align="center"></el-table-column>
            <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-edit" @click="showDetail(scope.row)">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <page-item :total="total" ref="pageItem"/>
        <driver-container-detail ref="detail"/>
    </div>
</template>

<script>
    import PageItem from '@/components/PageItem/'
    import DriverContainerDetail from './detail'
    import {getAllDriver} from '@/api/system/driver'
    import {getDriverContainer} from '@/api/delivery/delivery'

    export default {
        name: "DriverContainer",
        components: {DriverContainerDetail, PageItem},
        data(){
            return {
                queryParams: {
                    warehouseId: undefined,
                    driverId: undefined,
                    createTime: undefined
                },
                warehouseOption: this.$store.getters.warehouse,
                driverOption: [],
                tableData: [],
                total: 0,
                loading: false
            }
        },
        methods: {
            /** 获取列表数据 */
            getTableData(){
                if (!this.queryParams.warehouseId){
                    this.$message.error("请选择仓库");
                    return;
                }
                if (!this.queryParams.driverId){
                    this.$message.error("请选择司机");
                    return;
                }
                if (!this.queryParams.createTime){
                    this.$message.error("请选择查询日期");
                    return;
                }
                this.loading = true;
                this.queryParams.size = this.$refs['pageItem'].pageSize;
                this.queryParams.current = this.$refs['pageItem'].current;
                getDriverContainer(this.queryParams).then(({code, data})=>{
                    this.loading = false
                    if(code === 0){
                        this.tableData = data.pageData;
                        this.total = data.total;
                    }
                })
            },
            /** 查询 */
            querySearch(){
                this.$refs['pageItem'].current = 1;
                this.getTableData();
            },
            /** 修改 */
            showDetail(row){
                this.$refs.detail.handleOpen({warehouseId: this.queryParams.warehouseId, driverId: row.driverId, createTime: row.createTime});
            }
        },
        watch: {
            'queryParams.warehouseId': function (val, oldVal) {
                if(val) {
                    getAllDriver({warId: val}).then(({code, data}) => {
                        if (code === 0) {
                            this.driverOption = data;
                        }
                    })
                }
            }
        }
    }
</script>